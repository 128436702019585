import axios from 'axios'

import { LOCALE_COOKIE_NAME } from 'constants/cookie'

import { API_BASE_PATH } from 'constants/paths'
import { authenticateUserArgsToParams } from 'data/api/transformers/args'
import ApiClient, { interceptors } from 'libs/common/api-client'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import { CookieHandler } from 'libs/common/cookie-manager/types'
import { deleteCookie } from 'libs/utils/cookies'
import * as apiTypes from 'types/api'

const api = new ApiClient({}, [
  interceptors.csrfTokenInterceptor,
  interceptors.errorInterceptor,
  interceptors.localeInterceptor,
])

const ajaxApi = axios.create()
interceptors.csrfTokenInterceptor(ajaxApi)

export async function logoutUser({ cookies }: { cookies: CookieHandler }) {
  try {
    const response = await ajaxApi.post('/member/general/logout')

    cookies.set(cookiesDataByName.last_user_id, '1')
    deleteCookie(LOCALE_COOKIE_NAME)
    const isResponse2xx = `${response.status}`.startsWith('2')

    return isResponse2xx
  } catch {
    return false
  }
}

export function registerUser(args: apiTypes.RegisterUserArgs) {
  const {
    realName,
    login,
    email,
    password,
    agreeRules,
    subscribeToNewsletter = false,
    fingerprint,
  } = args

  const user = {
    real_name: realName,
    login,
    email,
    password,
    agree_rules: agreeRules,
    user_settings: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterUserResponse>(`${API_BASE_PATH}/users`, {
    user,
    fingerprint,
  })
}

export function registerFacebookUser(args: apiTypes.RegisterFacebookUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterFacebookUserResponse>(`${API_BASE_PATH}/facebook_users`, {
    fb_access_token: token,
    user,
    fingerprint,
  })
}

export function registerGoogleUser(args: apiTypes.RegisterGoogleUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterGoogleUserResponse>(`${API_BASE_PATH}/google_user`, {
    id_token: token,
    user,
    fingerprint,
  })
}

export function registerAppleUser(args: apiTypes.RegisterAppleUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterAppleUserResponse>(`${API_BASE_PATH}/apple_user`, {
    id_token: token,
    user,
    fingerprint,
  })
}

export function authenticateUser(args: apiTypes.LoginUserArgs) {
  return api.post<apiTypes.AuthenticateUserResponse, apiTypes.AuthenticateUserError>(
    '/oauth/token',
    {
      client_id: 'web',
      scope: 'user',
      ...authenticateUserArgsToParams(args),
    },
  )
}

export function resetPassword({ email }: apiTypes.ResetPasswordArgs) {
  return api.post(`${API_BASE_PATH}/users/reset_password`, {
    email,
  })
}

export function generateRandomUser(args: apiTypes.GenerateRandomUserArgs) {
  const { isGod } = args

  return api.post(`${API_BASE_PATH}/generate_random_user`, {
    is_god: isGod,
  })
}
