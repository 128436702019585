'use client'

import { useEffect, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { replaceUrlHost, urlWithParams } from 'libs/utils/url'
import { State as AppState } from 'state/types'
import * as actions from 'state/faq-entry/actions'
import * as selectors from 'state/faq-entry/selectors'
import { AccessChannel } from 'constants/index'

import { FaqEntryType } from 'constants/faq-entry'
import useLocation from 'hooks/useLocation'

type Props = {
  accessChannel?: AccessChannel
  type: FaqEntryType
  render: (faqUrl: string, faqEntryId: number) => ReactNode
}

const FaqEntryUrl = ({ type, render, accessChannel }: Props) => {
  const dispatch = useDispatch()
  const { host } = useLocation()
  const [entryUrl, setEntryUrl] = useState<string | null>(null)

  const faqEntry = useSelector((state: AppState) => selectors.getFaqEntryByType(state, type))

  useEffect(() => {
    dispatch(actions.fetchFaqEntryByTypeRequest({ type }))
  }, [type, dispatch])

  useEffect(() => {
    if (!faqEntry?.url) return

    const hostUrl = `http://${host}`
    const replacedUrl = replaceUrlHost(faqEntry.url, hostUrl)

    const finalUrl = accessChannel
      ? urlWithParams(replacedUrl, { access_channel: accessChannel })
      : replacedUrl

    setEntryUrl(finalUrl)
  }, [faqEntry, accessChannel, host])

  if (!entryUrl || !faqEntry) return null

  return <>{render(entryUrl, faqEntry.id)}</>
}

export default FaqEntryUrl
